.container {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2.5px 10px;
    padding: 5px 0px;
}

.key {
    display: flex;
    align-items: center;
    width: 25%;
    padding-right: 2%;
    cursor: auto;
}

.key>div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 2px;
}

.value {
    width: 75%;;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2%;
}

.value>div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 2px;
    
}
.value a {
    color: rgb(51, 51, 245);
}
.value a:hover{
    color: rgb(17, 17, 134);
}

.value:hover {
    color: var(--text-0);
}