:root {
    --bg-white-1: rgba(239, 239, 239, 0.966);
    --bg-white-10: rgba(245, 245, 245, 0.801);
    --bg-1: #0d1e25;
    --text-0: rgb(109, 163, 8);
    --text-1: rgb(145, 223, 0);
    --text-2: rgb(190, 243, 90);
    --text-3: rgb(213, 243, 157);

    --heading-0: rgb(75, 137, 230);
    --heading-1: rgb(93, 151, 238);
    --heading-5: rgb(93, 151, 238);
    --heading-8: rgba(204, 232, 240, 0.774);
    --heading-10: rgb(214, 228, 248);
}

* {
    box-sizing: border-box;

}

p {
    margin: 0px;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: var(--text-1);
}

.special {
    color: var(--text-1);
}

.size-20 {
    font-size: 20px;
}

.bold {
    font-weight: bold;
}

.error {
    color: red;
}

.border-error {
    border: 1px solid red !important;
}

.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

