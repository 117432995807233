.footer {
  width : 100%;  
  margin-top : auto;
  position: sticky;
}
.footer-container {
  background-color : var(--bg-1);
  color : white;
  height: 60px;
  margin-top: 50px;
}
.made-with-love {
  font-size: 12px;
  text-align : center;
  padding: 20px 0px;
}
.heart {
  font-style: normal;
  font-weight: 500;
  color: #c0392b;
}
