header {
    width: 100%;
    height: 85px;
    background-color: var(--bg-1);
    position: sticky;
    top: 0;
    z-index: 100;

}

.mainNav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.division {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 0 40px;
    
}

.active {
    color: var(--text-2)
}

.circle {
    width: 60px;
    height: 60px;
}

.logo {
    width: 70%;
    height: 70%;
    animation: rotate 10s infinite;
}


@keyframes rotate {
    85% {
        rotate: 0deg;
    }

    100% {
        rotate: 360deg;
    }
}