.container {
    width: 100%;
    height: 100%;

}


.main {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(252, 252, 252, 0)), url('../../image/bg-image-home.jpg');
    background-position: left;
    z-index: -1;
    opacity: 0.3;
    background-size: cover;
}

.left,
.right {
    width: 50%;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px 30px 20px;
}

.left h1 {
    margin-bottom: 0px;
    font-size: 50px;
}

.left p {
    font-size: 20px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    line-height: 30px;
    margin-bottom: 50px;
    width: 80%;
}

.growthImg {
    width: 30%;
    opacity: 0;
    position: absolute;
    bottom: 0px;
    visibility: hidden;
}


.getstartBtn {
    width: 120px;
}

.right {
    display: flex;
    justify-content: center;
    padding: 50px;
}

.right img {
    mix-blend-mode: multiply;
    width: 80%;

}