.container {
    width: 80%;
    max-width: 1170px;
    background-color: var(--bg-white-1);
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: rgb(176, 174, 174) 0px 0px 15px; */
    border: 1.5px solid #ccc;
}

.resultContainer{

    width: 100%;;
    border: 1px solid var(--heading-10);
    box-shadow: 0px 0px 5px #cccccc;
    border-radius: 10px;
    margin-top: 20px !important;   
    background-color:  rgba(204, 232, 240, 0.774);
}

.enlight {
    background-color: rgba(204, 232, 240, 0.774);
    border-radius: 7px;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.enlight p {
    margin: 5px;
    padding: 5px;

}
.party {
    width: 30px;
    height: 30px;
    margin-left: 20px;
}


.input {
    width: 100%;
}



.shortLink {
    background-color: rgb(240, 240, 240);
}

.create {
    width: 200px;
    height: 40px;
    margin-top: 30px;
    background-color: var(--text-1);
}

.free {
    font-size: 20px;
    font-weight: 500px;
    text-align: center;
    margin-top: 30px;
}

.free span {
    color: var(--heading-1);
    font-size: 20px;
    font-weight: bold;

}