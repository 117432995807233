

.button {
  background-color: initial;
  background-image: linear-gradient(-180deg, var(--text-0), var(--text-1));
  /* background-image: linear-gradient(-180deg, #FF7E31, #E62C03); */
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 40px;
  line-height: 40px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  max-width: 100%;
  z-index: 9;
  border: 0;
  transition: box-shadow .2s;
}

.button:hover {
  background-image: linear-gradient(-180deg, #e4661e, #E62C03);
  color : #fff
}