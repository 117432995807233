.heading {    
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.content {
    max-width: 900px;
    width: 70%;
}
