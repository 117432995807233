.form-container,
.login-form,
.register-form {
  display: flex;
  flex-direction: column;
  max-width: 90%;
}

.form-container {
  padding: 4rem;
  border: 2px solid rgb(245, 215, 215);
  border-radius: 15px;
  margin: 0.5rem;
}

.link-btn {
  background: none;
  text-decoration: underline;
  color: white;
  height: 30px;
}
.link-btn:hover,
.link-btn:active {
  color: rgb(86, 86, 245);
}

.active {
  color: var(--text-2);
}
